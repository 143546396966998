<template>
  <div ref="tableWrapper" class="max-width-7 md-px1 px0 lg-px2 mx-auto baby-compare-table-wrapper">
    <!-- Aquariums to compare -->
    <div class="tank-table-row-wrapper bg-color-white">
      <div class="tank-table-row bg-color-white">
        <div class="flex no-outline-mobile">
          <div class="rsf-table-cell col-12"></div>
          <div class="rsf-table-cell tank-table-cell col-12" v-for="(product, index) in detailedProducts"
            :key="product.id">
            <a :href="product.ProductsURL ? product.ProductsURL : '#'">
              <img class="col-12 contain pb2 rsf-compare-table-product-img"
                :src="category.dataProducts[index].ImageUrl" />
                <h3 class="h5 helvetica-bold center px1">{{product.Model}}</h3>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Comapre Table -->
    <div class="rsf-compare-table-wrapper bg-color-white">
      <div class="rsf-compare-table relative">

        <div class="flex" v-if="displayRatingRow">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_overall_rating') }}</h3>
          </div>
          <div class="rsf-table-cell col-12 flex justify-center" v-for="product in detailedProducts" :key="product.id">
            <a :href="product.ReviewsURL + `#/${siteLanguage}/model=${product.RNumber}`" target="_blank" rel="noopener" class="flex items-center justify-center w-fit-content mx-auto" style="gap: 4px">
              <svg v-if="product.Rating && Number(product.Rating > 0)" class="block mx-auto" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.48795 0.787717C7.55057 0.660842 7.73149 0.660842 7.7941 0.787717L9.60825 4.46359C9.69469 4.63872 9.86177 4.76012 10.055 4.7882L14.1116 5.37765C14.2516 5.398 14.3075 5.57006 14.2062 5.66882L11.2709 8.53009C11.131 8.66641 11.0672 8.86283 11.1002 9.05532L11.7931 13.0955C11.8171 13.2349 11.6707 13.3413 11.5455 13.2754L7.91716 11.3679C7.74429 11.2771 7.53776 11.2771 7.36489 11.3679L3.73659 13.2754C3.61136 13.3413 3.46499 13.2349 3.48891 13.0955L4.18185 9.05532C4.21487 8.86283 4.15105 8.66641 4.0112 8.53009L1.07584 5.66882C0.974523 5.57006 1.03043 5.398 1.17045 5.37765L5.22701 4.7882C5.42028 4.76012 5.58736 4.63872 5.6738 4.46359L7.48795 0.787717Z"
                  fill="#FFC700" stroke="#FFC700" stroke-width="0.422695" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p :class="`open-sans-var h5 color-black nowrap ${product.Rating && product.Rating > 0 ? 'underline' : ''}`">{{ product.Rating && product.Rating > 0 ? product.Rating : '-' }}</p>
            </a>

          </div>
        </div>


        <div class="flex" v-if="displayARRow">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_seeTank_Title') }}</h3>
          </div>
          <div class="rsf-table-cell col-12" v-for="product in detailedProducts" :key="product.id">
            <div class="flex justify-center items-center ar-gap open-sans-var h5 color-black" v-show="!product.ARData.modelAvailable">-</div>
            <div class="flex justify-center items-center ar-gap w-fit-content mx-auto" v-show="product.ARData.modelAvailable" @click="prepareARView(product.ARData)" >
              <a class="color-1 open-sans-semibold h-12p lg-h5 compare-ar-link pointer"><span class="hide lg-block open-sans-semibold nowrap">{{$t('rsf_ar_see_at_home')}}</span><span class="lg-hide open-sans-semibold nowrap">{{$t('rsf_seeTank') }}</span></a>
              <svg class="compare-ar-link-icon pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" style="margin-bottom: -1px;" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 19.5002L18.5 20.6598" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M3.01367 19.1461L5.5 19.8753" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M20.9812 5.16974L18.1881 4.23484L17.7402 4.08495" stroke="#1867FF" stroke-width="1.3"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3 6.00031L6 5.2177" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M20.9922 5.16986L21 9.00018" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M20.9922 16.5002L21 19.5002" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M3 6.00031L3 9.50031" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M3.01367 16.0002L3.01367 19.146" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M5.45508 17.0457L14.4551 19.5003L18.546 17.8639" stroke="#1867FF" stroke-width="1.3"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.45508 8.04572L14.4551 6.00026L18.546 7.22753" stroke="#1867FF" stroke-width="1.3"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.45508 12.5457H14.4551H18.5458" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M14.4551 6.40948L14.4551 19.0913" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M18.5527 7.2276L18.5527 17.864" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M5.45508 8.04572L5.45508 17.0457" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M9.54688 12.9549V17.864" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_sysVols_total') }}</h3>
          </div>
          <UnitCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :measurement="measurement"
            :metricValue="product.SysVol_TotalSystemWaterVol_MetricLiter"
            metricSuffix=" L"
            :imperialValue="product.SysVol_TotalSystemWaterVol_ImperialGallon"
            imperialSuffix=" gal"
          />
        </div>
        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_sysVols_aqVolume') }}</h3>
          </div>
          <UnitCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :measurement="measurement"
            :metricValue="product.SysVol_DisplayTankWaterVol_MetricLiter"
            metricSuffix=" L"
            :imperialValue="product.SysVol_DisplayTankWaterVol_ImperialGallon"
            imperialSuffix=" gal"
          />
        </div>
        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_sysVols_cabinet') }}</h3>
          </div>
          <StringCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :measurement="measurement"
            :metricValue="product.SysVol_InCabinetSumpWaterVol_MetricLiter"
            :imperialValue="product.SysVol_InCabinetSumpWaterVol_ImperialGallon"
          />
          
        </div>

        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_tank_length') }}</h3>
          </div>
          <UnitCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :measurement="measurement"
            :metricValue="product.SysDim_DisplayTankLength_MetricCm"
            metricSuffix=" cm"
            :imperialValue="product.SysDim_DisplayTankLength_ImperialInch"
            imperialSuffix=' "'
          />
          
        </div>
        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_tank_width') }}</h3>
          </div>
          <UnitCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :measurement="measurement"
            :metricValue="product.SysDim_DisplayTankWidth_MetricCm"
            metricSuffix=" cm"
            :imperialValue="product.SysDim_DisplayTankWidth_ImperialInch"
            imperialSuffix=' "'
          />
        </div>
        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_tank_height') }}</h3>
          </div>
          <UnitCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :measurement="measurement"
            :metricValue="product.SysDim_DisplayTankHeight_MetricCm"
            metricSuffix=" cm"
            :imperialValue="product.SysDim_DisplayTankHeight_ImperialInch"
            imperialSuffix=' "'
          />
          
        </div>
        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_total_height') }}</h3>
          </div>
          <UnitCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :measurement="measurement"
            :metricValue="product.SysDim_TotalSystemHeight_MetricCm"
            metricSuffix=" cm"
            :imperialValue="product.SysDim_TotalSystemHeight_ImperialInch"
            imperialSuffix=' "'
          />
        </div>




        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_front_glass') }}</h3>
          </div>
          <CalcCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :measurement="measurement"
            :metricValue="product.GLA_UltraClearFrontGlass_MetricCm"
            metricSuffix=" mm"
            :imperialValue="product.GLA_UltraClearFrontGlass_ImperialInch"
            imperialSuffix=' "'
          />
        </div>
        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_side_glass') }}</h3>
          </div>
          <CalcCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :measurement="measurement"
            :metricValue="product.GLA_UltraClearSideGlass_MetricCm"
            metricSuffix=" mm"
            :imperialValue="product.GLA_UltraClearSideGlass_ImperialInch"
            imperialSuffix=' "'
          />
        </div>

        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_bottom_glass') }}</h3>
          </div>
          <CalcCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :measurement="measurement"
            :metricValue="product.GLA_UltraClearBottomGlass_MetricCm"
            metricSuffix=" mm"
            :imperialValue="product.GLA_UltraClearBottomGlass_ImperialInch"
            imperialSuffix=' "'
          />
        </div>

        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_sysVols_reefled') }}</h3>
          </div>
          <StringCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :measurement="measurement"
            :metricValue="product.Lig_ReefLedAdvancedLightSystem_Text"
            :imperialValue="product.Lig_ReefLedAdvancedLightSystem_Text"
          />
        </div>

        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_total_circulation_table_heading') }}</h3>
          </div>
          <StringCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :measurement="measurement"
            :metricValue="product.TotCir_TotalCirculation_MetricCm"
            :imperialValue="product.TotCir_TotalCirculation_ImperialInch"
          />
        </div>

        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_return_sump_main_system_pump') }}</h3>
          </div>
          <StringCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :measurement="measurement"
            :metricValue="product.SumRetSys_MainSystemPump_MetricCm"
            :imperialValue="product.SumRetSys_MainSystemPump_ImperialInch"
          />
        </div>

        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_filtration_protein') }}</h3>
          </div>
          <StringCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :measurement="measurement"
            :metricValue="product.Filt_ProteinSkimmer_MetricLiter"
            :imperialValue="product.Filt_ProteinSkimmer_ImperialGallon"
          />
        </div>

        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_compare_table_head_reefmat') }}</h3>
          </div>
          <StringCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :measurement="measurement"
            :metricValue="product.Filt_reefmat"
            :imperialValue="product.Filt_reefmat"
          />
        </div>

        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_reefato_kit') }}</h3>
          </div>
          <BooleanTableCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :value="product.reAtPl_ReefAtoPlusKit == 'Y' ? true : false" 
          />
        </div>

        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_reefato_ready') }}</h3>
          </div>
          <div class="rsf-table-cell col-12" v-for="product in detailedProducts" :key="product.id">
            <p class="open-sans-var h5 color-black center">{{ product.reAtPl_ReefAtoPlusReady ? product.reAtPl_ReefAtoPlusReady : '-'  }}</p>
          </div>
        </div>

        <div class="flex">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_compare_table_head_slide_out_panel') }}</h3>
          </div>
          <StringCell 
            v-for="product in detailedProducts" 
            :key="product.id"
            :measurement="measurement"
            :metricValue="product.Cab_slideOutControlPanel"
            :imperialValue="product.Cab_slideOutControlPanel"
          />
        </div>


        <div class="rsf-compare-table-heading-row flex justify-center items-center gap3" v-show="siteLanguage != 'cn' && siteLanguage != 'ja'">
          <h3 class="lg-h4 h5 open-sans-semibold">{{ $t('rsf_recommen_price') }}</h3>
          <CountrySelect :updateCountry="updateCountry" :siteLanguage="siteLanguage" :countries="countries"
            :selectedCountry="selectedCountry" />
        </div>

        <div class="flex" v-show="selectedCountry && displayFirstRow" v-if="siteLanguage != 'cn' && siteLanguage != 'ja'">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_compare_prices_white') }}</h3>
          </div>
          <div class="rsf-table-cell col-12 py2" v-for="product in detailedProducts" :key="product.id">
            <p class="open-sans-var h5 color-black center">{{ product.priceList.firstElement ?
              product.priceList.firstElement.RetailPrice == '0.0000' ? 'N/A' :
                formatCurrency(product.priceList.firstElement.Currency, product.priceList.firstElement.RetailPrice,
                  product.priceList.firstElement.IsoCode) : 'N/A' }}</p>
          </div>
        </div>
        <div class="flex" v-show="selectedCountry && displaySecondRow" v-if="siteLanguage != 'cn' && siteLanguage != 'ja'">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_compare_prices_black') }}</h3>
          </div>
          <div class="rsf-table-cell col-12 py2" v-for="product in detailedProducts" :key="product.id">
            <p class="open-sans-var h5 color-black center">{{ product.priceList.secondElement ?
              product.priceList.secondElement.RetailPrice == '0.0000' ? 'N/A' :
                formatCurrency(product.priceList.secondElement.Currency, product.priceList.secondElement.RetailPrice,
                  product.priceList.firstElement.IsoCode) : 'N/A' }}</p>
          </div>
        </div>
        <div class="flex" v-show="selectedCountry && displayThirdRow" v-if="siteLanguage != 'cn' && siteLanguage != 'ja'">
          <div class="rsf-table-cell col-12">
            <h3 class="open-sans-regular color-black h6">{{ $t('rsf_compare_prices_no_cabinet') }}</h3>
          </div>
          <div class="rsf-table-cell col-12 py2" v-for="product in detailedProducts" :key="product.id">
            <p class="open-sans-var h5 color-black center">{{ product.priceList.thirdElement ?
              product.priceList.thirdElement.RetailPrice == '0.0000' ? 'N/A' :
                formatCurrency(product.priceList.thirdElement.Currency, product.priceList.thirdElement.RetailPrice,
                  product.priceList.firstElement.IsoCode) : 'N/A' }}</p>
          </div>
        </div>
      </div>
      <!-- comapre table end -->
    </div>
    <!-- compare table warpper end -->
    <div class="justify-center mt5 gap2 px2 border-box hide lg-flex compare-dual-btns" v-if="siteLanguage != 'cn'">
      <a :href="'https://redseafish.com/' + siteLanguage + '/myreefer/'" class="rsf-btn rsf-btn-std bg-color-1 color-white no-shrink h5 open-sans-regular">{{ $t('rsf_build_with_myrefeer') }}</a>
      <a :href="'https://redseafish.com/' + siteLanguage + '/compare-systems/'" class="rsf-btn rsf-btn-tertiary color-1 center h5 open-sans-regular" style="white-space:break-spaces;">
        {{$t('rsf_compare_more_models') }}
      </a>
    </div>
    <div class="justify-center mt5 gap2 px2 border-box hide lg-flex compare-dual-btns" v-if="siteLanguage == 'cn'">
      <a :href="'http://www.redseafish.cn/compare-wizard/'" class="rsf-btn rsf-btn-std bg-color-1 color-white no-shrink h5 open-sans-regular">{{$t('rsf_compare_more_models') }}</a>
    </div>

  </div>
  
</template>

<script>

import BooleanTableCell from './BooleanTableCell.vue';
import CountrySelect from './CountrySelect.vue';
import axios from 'axios';
import { EventBus } from '@/event-bus';
import UnitCell from './UnitCell.vue';
import StringCell from './StringCell.vue';
import CalcCell from './CalcCell.vue';
import CompareModal from './CompareModal.vue';

export default {
  name: 'BabyComapreTable',
  props: {
    category: Object,
    siteLanguage: String,
    formatCurrency: Function,
    selectedCountry: Object,
    updateCountry: Function,
    measurement: String,
    countries: Array,
    isDesktop: Boolean,
    index: Number,
    compareId: String
  },
  data() {
    return {
      detailedProducts: [],
      loading: false,
      fetcheddProducts: false,
      chinaVariant: process.env.VUE_APP_RSF_CHINA_VARIANT
    }
  },
  components: {
    BooleanTableCell,
    CountrySelect,
    UnitCell,
    StringCell,
    CalcCell,
    CompareModal
  },
  methods: {
    getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    },
    prepareARView(ARData){
        let device = this.getMobileOperatingSystem();

        if(device == 'Windows Phone' || device == 'unknown'){
          EventBus.$emit('open-ar-modal', {
            arQrCode: ARData.auto_qr,
          })
        }

        let templateLink = ARData.link.replace('/en/', `/${this.siteLanguage}/`)

        if(device == 'Android'){
            window.location.href = templateLink;
        }

        if(device == 'iOS'){
            window.location.href = templateLink;
        }

    },
    fetchDetailedData() {
      console.log('fetch detailed data')

      this.loading = true
      let currentObj = this;
      let params = {
        productIdsList: [],
        lang: this.siteLanguage,
        isocode: this.selectedCountry.isocode2
      }

      if(this.isDesktop){
        for (let i = 0; i < this.category.dataProducts.length; i++) {
          params.productIdsList.push(this.category.dataProducts[i].id)
        }
      }else{
        for (let i = 0; i < this.category.subCategories[this.index].length; i++) {
            params.productIdsList.push( this.category.subCategories[this.index][i].id)
          }
      }

      if (this.detailedProducts.length > 0) {
        console.log('prdouct data already fetched')
        this.updateProductImgs();
        this.getRetailPrice();
        return;
      }
      setTimeout(() => {
        axios
          .post(process.env.VUE_APP_RSF_API + "/public-api/comparisons/get-product-details/json", params)
          .then(function (response) {
            let fetchedProducts = response.data.listOfProducts;
            setTimeout(() => { currentObj.loading = false }, 500)
            for (let x = 0; x < fetchedProducts.length; x++) {
              currentObj.detailedProducts.push(fetchedProducts[x])
            }
            console.log('get retailprice from fetchdetailedata')

          })
          .catch(function (error) {

          });
      })

      this.updateProductImgs();
      this.getRetailPrice();
    },
    getRetailPrice() {
      let that = this;
      console.log(this.selectedCountry.isocode2)
      if (this.detailedProducts.length < 1) { return; }
      for (let i = 0; i < this.detailedProducts.length; i++) {
        let url = process.env.VUE_APP_RSF_API + '/public-api/prices/get-retailprice-compare/' + this.compareId + '/' + this.detailedProducts[i].RNumber + '/' + this.selectedCountry.isocode2 + '/json'
        axios
          .get(url)
          .then(function (response) {
            // that.detailedProducts[i].RetailPrice = response.data.RetailPrice
            // that.detailedProducts[i].Currency = response.data.Currency
            that.detailedProducts[i].priceList = response.data.priceList;
          })
          .catch(function (error) {

          });
      }
    },
    computeProductImg(product) {
      return product.ImageUrl && require(`../assets/images/${this.product.ImageUrl}`)
    },
    updateProductImgs() {
      for (let i = 0; i < this.detailedProducts.length; i++) {
        this.detailedProducts[i].ImageUrl = this.detailedProducts[i].ImageUrl
      }
    },
    isNumeric(value) {
      return Number.isInteger(value);
    }
  },
  created() {
    console.log('comapre table created')
    EventBus.$on('fetch_detailed_data', () => {
      console.log('table received fetch_detailed_data')
      this.fetchDetailedData();
    })
    EventBus.$on('update_retail_price', () => {
      this.getRetailPrice()
    });
  },
  mounted() {
    this.fetchDetailedData();
      if(!this.isDesktop){
        if(this.category.subCategories[this.index].length == 1){
          this.$refs.tableWrapper.classList.add('single-item-table')
          console.log(this.$refs.tableWrapper)
        }
      }
  },

  computed: {
    hasMyReefer() {
      let hasMyReeferBtn = 0
      for (let i = 0; i < this.detailedProducts.length; i++) {
        if (this.detailedProducts[i].buttonTarget == 'my-reefer') {
          hasMyReeferBtn++
        }
      }
      if (hasMyReeferBtn == 0) {
        return false
      } else {
        return true
      }
    },
    displayFirstRow() {
      let itemsInRow = 0;
      for (let i = 0; i < this.detailedProducts.length; i++) {
        if (this.detailedProducts[i].priceList.firstElement) {
          itemsInRow++
        }
      }
      if (itemsInRow > 0) {
        return true;
      }
      return false;
    },
    displaySecondRow() {
      let itemsInRow = 0;
      for (let i = 0; i < this.detailedProducts.length; i++) {
        if (this.detailedProducts[i].priceList.secondElement) {
          itemsInRow++
        }
      }
      if (itemsInRow > 0) {
        return true;
      }
      return false;
    },
    displayThirdRow() {
      let itemsInRow = 0;
      for (let i = 0; i < this.detailedProducts.length; i++) {
        if (this.detailedProducts[i].priceList.thirdElement) {
          itemsInRow++
        }
      }
      if (itemsInRow > 0) {
        return true;
      }
      return false;
    },
    // displayATORow() {
    //   let itemsInRow = 0;
    //   for (let i = 0; i < this.detailedProducts.length; i++) {
    //     if (this.detailedProducts[i].reAtPl_ReefAtoPlusKit == 'Y') {
    //       itemsInRow++
    //     }
    //   }
    //   if (itemsInRow > 0) {
    //     return true;
    //   }
    //   return false;
    // },
    displayRatingRow(){
      if(process.env.VUE_APP_RSF_CHINA_VARIANT == 'true'){
        return false;
      }
      let itemsInRow = 0;
      for (let i = 0; i < this.detailedProducts.length; i++) {
        if (this.detailedProducts[i].Rating && this.detailedProducts[i].Rating > 0) {
          itemsInRow++
        }
      }
      if(itemsInRow > 0){
        return true
      }else{
        return false
      }
    },
    displayARRow(){
      if(process.env.VUE_APP_RSF_CHINA_VARIANT == 'true'){
        return false;
      }
      let itemsInRow = 0; 
      for(let i = 0; i < this.detailedProducts.length; i++){
        if(this.detailedProducts[i].ARData.modelAvailable){
          itemsInRow++
        }
      }
      if(itemsInRow > 0){
        return true;
      }
      return false;
    }
  }
}
</script>

<style scoped>
@media(min-width: 1024px ){
  .tank-table-row-wrapper{
    top: var(--top-menu-height);
  }
}

.compare-ar-link{
  text-decoration: underline; 
  text-decoration-thickness: 1px;
  text-underline-offset: 1.5px;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
}



.ar-gap{
  gap: 2px;
}


@media(min-width: 1024px){
  .ar-gap{
    gap: 8px;
  }

  .compare-ar-link{
    font-size: 1rem;
  }
}

@media(max-width: 330px){
  .compare-ar-link{
    font-size: 0.5rem;
  }

  .compare-ar-link-icon{
    width: 16px;
  }
}
</style>
